<!-- eslint-disable max-len -->
<template>
  <div>
    <v-navigation-drawer v-if="isLoggedIn" v-model="drawer" color="#8c6384" app temporary>
      <v-list dense>
        <v-list-item>
          <v-list-item-action>
            <v-icon color="white">{{ mdiTranslate }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>English</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(menuItem, index) in menuItems" :key="index">
          <router-link v-if="menuItem.path" :to="menuItem.path">
            <v-list-item-action>
              <v-icon color="white">{{ menuItem.icon }}</v-icon>
            </v-list-item-action>
          </router-link>
          <a v-else :href="menuItem.href">
            <v-list-item-action>
              <v-icon color="white">{{ menuItem.icon }}</v-icon>
            </v-list-item-action>
          </a>
          <v-list-item-content v-if="menuItem.path">
            <router-link :to="menuItem.path">
              <v-list-item-title>{{ menuItem.text }}</v-list-item-title>
            </router-link>
          </v-list-item-content>
          <v-list-item-content v-else>
            <a :href="menuItem.href">
              <v-list-item-title>{{ menuItem.text }}</v-list-item-title>
            </a>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon color="white">{{ mdiAccount }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider
        v-if="isInRole('Administrators')"></v-divider>
      <v-subheader
        v-if="isInRole('Administrators')">
        <span
          style="color: #ffffff"
        >
          <span>Admin</span>
        </span>
      </v-subheader>
      <v-list dense
        v-if="isInRole('Administrators')">
        <v-list-item v-for="(menuItem, index) in adminMenuItems" :key="index">
          <router-link :to="menuItem.to">
            <v-list-item-action>
              <v-icon color="white">{{ menuItem.icon }}</v-icon>
            </v-list-item-action>
          </router-link>
          <v-list-item-content>
            <router-link :to="menuItem.to">
              <v-list-item-title>{{ menuItem.text }}</v-list-item-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="isLoggedIn" class="primary--text" app flat color="transparent">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      Hello,<b class="ml-1">{{ username }}</b>
      <v-spacer />
      <a href="https://www.skillosophia.com">
        <v-img src="../assets/FlowerCircle.svg" max-height="50" max-width="50" contain />
      </a>
    </v-app-bar>
    <v-app-bar v-else class="primary--text" app flat color="transparent">
      <v-spacer></v-spacer>
      <v-btn class="mr-2" href="/auth/login" color="primary" text>Login</v-btn>
      <v-btn href="/auth/register" color="primary" text>Register</v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  mdiTranslate,
  mdiMagnify,
  // mdiPlayCircle,
  mdiFormatListBulleted,
  mdiHistory,
  mdiAccount,
  mdiAccountMultiple,
  mdiCog,
  mdiHeadQuestionOutline,
  mdiPointOfSale,
  mdiEmoticonHappyOutline,
  mdiAccountPlus,
  mdiCogBox,
  mdiBrain,
  mdiBriefcaseSearch,
} from '@mdi/js';

export default {
  data() {
    return {
      mdiTranslate,
      mdiAccount,
      drawer: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapState('auth', ['username', 'isLoggedIn']),
    adminMenuItems() {
      return [
        { text: this.$t('menu.subCompetencies'), icon: mdiHeadQuestionOutline, to: { name: 'admin-ListKeyword' } },
        { text: this.$t('menu.settings'), icon: mdiCog, to: { name: 'EditKeyword' } },
        { text: this.$t('menu.joinRequests'), icon: mdiAccountPlus, to: { name: 'admin-ListJoinRequests' } },
        { text: this.$t('menu.saleLeads'), icon: mdiPointOfSale, to: { name: 'admin-ListSaleLeads' } },
        { text: this.$t('menu.feedback'), icon: mdiEmoticonHappyOutline, to: { name: 'admin-ListFeedback' } },
        { text: this.$t('menu.eventLog'), icon: mdiCogBox, to: { name: 'admin-EventLogs' } },
        { text: this.$t('menu.aiSettings'), icon: mdiBrain, to: { name: 'admin-AISettings' } },
        { text: this.$t('menu.users'), icon: mdiAccountMultiple, to: { name: 'admin-UserManagementHome' } },
      ];
    },
    menuItems() {
      return [{
        icon: mdiMagnify,
        text: 'Research Skills',
        path: { name: 'AuthHome' },
        // href: '/',
      },
      { text: this.$t('menu.jobDescriptionLookup'), icon: mdiBriefcaseSearch, path: { name: 'jobDescriptionLookup' } },
      {
        icon: mdiFormatListBulleted,
        text: 'Saved Playlists',
        path: { name: 'SavedSkillSets' },
      },
      // {
      //   icon: mdiPlayCircle,
      //   text: 'Start Playlist',
      //   path: { name: 'StartInterview' },
      // },
      {
        icon: mdiHistory,
        text: 'History',
        path: { name: 'Interviews' },
      },
      ];
    },
  },
  methods: {
    logout() {
      this.$root.$emit('auth:logoff');
    },
  },
};
</script>
<style scoped>
.v-list-item__title {
  color: white !important;
}
</style>
